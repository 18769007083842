import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import axios, { CancelToken, isCancel } from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Services from "../../Services/auth.service";
import Services1 from "../../Services/auth.service1";
import authServiceEvent from "../../Services/auth.service.event";
import authHeader from "../../Services/auth.header";
import authAdminHeader from "../../Services/auth.admin.header";
import frame from "../../Images/Frame.svg";
import csvsupport from "../../Images/cbs-logo2.png";
import Loader2 from "../../Images/loader2.gif";
import Playicon from "../../Images/play-icon.svg";
import VideoDetailBAck from "../../Images/short_left.png";
import DocImg from "../../Images/docImg.png";
import PdfImg from "../../Images/pdfImg.svg";
import ProfileIcon from "../AdminCommonComponent/ProfileIcon";
import authService from "../../Services/auth.service";
import TinyEditor from "../AdminCommonComponent/TinyEditor";
import AlertComponent from "../../Hooks/AlertComponent";
import AddRules from "../CommonRules/AddRules";
import { useUserCountMutation } from "../../Services/ruleUserCount";
import ImageCropperReact from "../ImageCropper/ImageCropperReact";
import { CompressImageFunction } from "../AdminNotification/CommonFunction";

const AddVideo = () => {
  //hooks

  const cancelUpload = useRef(null);
  const descriptionRef = useRef(null);
  const editdescriptionRef = useRef(null);
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const loc = useRef(window.location.href);
  const latestRequest = useRef(0);

  //redux states
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner);
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });
  const videoInprocess = useSelector((state) => {
    return state.ReduVideoInProcess;
  });
  const [abortProcess, setabortProcess] = useState(false);
  const [abortvideoId, setabortvideoId] = useState("");
  const [uploadInprocess, setuploadInprocess] = useState(false);


  const [allgroup, setallgroup] = useState([]);
  const [err, setErr] = useState({
    cliff: "",
    files: "",
    prtn: "",
    name: "",
    email: "",
  });
  const [videoDetail, setvideoDetail] = useState({
    video: [],
    title: "",
    categories: [],
    tags: [],
    speakers: [],
    clif_notes: "",
    files: [],
    group_ids: [],
    eventIds: [],
    starting_view_cnt: 0,
    upload_date: "",
    eventFor: "others",
    makeFeaturedCheckbox: false,
  });
  const [videoDetailErr, setvideoDetailErr] = useState({
    title: "",
    video: "",
    categories: "",
    url: "",
    starting_view_cnt: "",
    upload_date: "",
    membership: "",
    fileSize: "",
  });
  const [addSpeaker, setAddSpeaker] = useState({
    profilePic: "",
    guestIcon: "",
    partnerIcon: "",
    speakerIcon: "",
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    profession: "",
    phone: "",
    auth0Id: "",
    facebook: "",
    linkedin: "",
    passcode: "",
    displayName: "",
  });
  const [Inprocess, setInprocess] = useState(false);
  const [addInprocess, setAddInprocess] = useState(false);
  const [partner, setpartner] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [addpartner, setaddpartner] = useState({ logo: [], name: "", url: "" });
  const [addfile, setaddfile] = useState({ name: "", file: [] });
  const [clif_note, setclif_note] = useState(EditorState.createEmpty());
  const [subcategories, setsubcategories] = useState([]);
  const [showalert, setshowalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [allEvents, setAllEvents] = useState([]);
  const [createInprocess, setcreateInprocess] = useState(false);
  const [showalertAdd, setshowalertAdd] = useState(false);
  const [alertmsgAdd, setalertmsgAdd] = useState("");
  const [addtag, setaddtag] = useState("");
  const [adderr, setadderr] = useState("");
  const [allcategories, setallcategories] = useState([]);
  const [alltags, setalltags] = useState([]);
  const [allspeakers, setallspeakers] = useState([]);
  const [tempvideo, settempvideo] = useState("");
  const [selectedAllGroup, setselectedAllGroup] = useState(false);
  const [progbarVal, setprogbarVal] = useState(0);
  const [thumimgsize, setThumimgsize] = useState(false);
  const [compressInprocess, setcompressInprocess] = useState(false);
  const [showPopup, setshowPopup] = useState(false);
  const [changePage, setchangePage] = useState("");
  const [reloadPage, setreloadPage] = useState(false);
  const [events, setevents] = useState([]);
  const [existingModalAlert, setExistingModalAlert] = useState("");
  const [dataFromRules, setDataFromRules] = useState([]);
  const [ruleError, setRuleError] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [noRuleFieldSelected, setNoRuleFieldSelected] = useState(false);
  const [totalUserList, setTotalUserList] = useState([]);

  //  image cropping state
  const [fileName, setFileName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState([]);
  const [thumbnailCroppedFileData, setThumbnailCroppedFileData] = useState();
  const [fileDimension, setFileDimension] = useState();
  const [requiredImageSize, setRequiredImageSize] = useState({
    width: 1280,
    height: 720,
  });
  const [requiredImageAspectRatio, setRequiredImageAspectRatio] = useState(
    16 / 9
  );
  const [selectImageType, setSelectImageType] = useState("");
  const [partnerImgData, setPartnerImgData] = useState([]);


  const [users, setUsers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [nonUsers, setNonUsers] = useState([]);
  const [invalidEmail, setInvalidEmail] = useState([]);
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;


  const onEditorStateChange = (editorState) => {
    console.log(
      stateToHTML(editorState.getCurrentContent(), {
        defaultBlockTag: null,
      }).replace(/&nbsp;$/, "")
    );
    var text = stateToHTML(editorState.getCurrentContent(), {
      defaultBlockTag: null,
    }).replace(/&nbsp;$/, "");
    if (text.length > 0 && text !== "<br>") {
      setvideoDetailErr({ ...videoDetailErr, description: "" });
    } else {
      setvideoDetailErr({
        ...videoDetailErr,
        description: "Enter description!",
      });
    }

    setEditorState(editorState);
  };
  //count hook
  //User count mutation
  const [userCountApi] = useUserCountMutation();
  const countUserApi = async (bodyData, requestId) => {
    try {
      const countRes = await userCountApi(bodyData);
      if (latestRequest.current === requestId) {
        // Only update state if this is the latest request
        setTotalUserList(countRes?.data?.data);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    const bodyData = {
      restrictedAccessUserId: dataFromRules.user_id,
      restrictedAccessMembershipPlanId: dataFromRules.membership_plan_id,
      restrictedAccessGroupId: dataFromRules.group_id,
      restrictedAccessTagId: dataFromRules.tags_id,
      restrictedAccessEventId: dataFromRules.event_id,
      restrictionAccess:
        dataFromRules.userAccess === "all" ? "public" : "restricted",
    };
    const requestId = ++latestRequest.current; // Increment the request ID
    countUserApi(bodyData, requestId);
  }, [dataFromRules]);

  useEffect(() => {
    handleClearAllStates();
    authServiceEvent
      .getAllEventsLimitedFiedls()
      .then((res) => {
        if (res.data.status) {
          setAllEvents(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    Services.getallcontentcategory_byadmin("")
      .then((res) => {
        if (res.data.status) {
          setallcategories(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    Services.getalltags_byadmin("")
      .then((res) => {
        if (res.data.status) {
          setalltags(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    Services.getSpeakerList()
      .then((res) => {
        if (res.data.status) {
          setallspeakers(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    Services.getallgroup()
      .then((res) => {
        if (res.data.status) {
          setallgroup(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    Services1.getallevent_byadmin("")
      .then((res) => {
        if (res.data.status) {
          // console.log(res.data.data,'dgfg')
          setevents(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    //window.addEventListener("beforeunload", alertUser);
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
    return () => {
      //window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    if (videoInprocess) {
      e.preventDefault();
      setshowPopup(true);
      setreloadPage(true);
      return "";
    } else {
      window.location.reload(true);
    }
  };

  const checkDesciptionValue = (state) => {
    var text = descriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (e.target.name === "title") {
      e.target.value.length === 0
        ? setvideoDetailErr({ ...videoDetailErr, title: "Enter title!" })
        : setvideoDetailErr({ ...videoDetailErr, title: "" });
    }
    if (e.target.name === "starting_view_cnt") {
      e.target.value < 0
        ? setvideoDetailErr({
          ...videoDetailErr,
          starting_view_cnt: "Value must be greater than 0!",
        })
        : setvideoDetailErr({ ...videoDetailErr, starting_view_cnt: "" });
    }
    if (e.target.name === "upload_date") {
      const enteredDate = new Date(e.target.value);
      const currentDate = new Date();
      const year2000 = new Date("2000-01-01");

      if (enteredDate > currentDate) {
        setvideoDetailErr({
          ...videoDetailErr,
          upload_date: "Selected date must be in the past!",
        });
      } else if (enteredDate < year2000) {
        setvideoDetailErr({
          ...videoDetailErr,
          upload_date: "Selected year must be 2000 or later!",
        });
      } else {
        setvideoDetailErr({ ...videoDetailErr, upload_date: "" });
      }
      const currentTime = moment().format("HH:mm:ss");
      const combinedDateTime = moment(
        `${e.target.value}T${currentTime}`
      ).toISOString();
      setvideoDetail({ ...videoDetail, [e.target.name]: combinedDateTime });
    } else {
      setvideoDetail({ ...videoDetail, [e.target.name]: e.target.value });
    }
  };

  const handlePublish = async () => {
    var text = stateToHTML(editorState.getCurrentContent(), {
      defaultBlockTag: null,
    }).replace(/&nbsp;$/, "");
    if (
      videoDetail.video.length > 0 &&
      !thumimgsize &&
      videoDetail.title.length > 0 &&
      videoDetail.categories.length > 0 &&
      videoDetailErr.starting_view_cnt.length === 0 &&
      videoDetailErr.upload_date.length === 0 &&
      !ruleError &&
      !noRuleFieldSelected
    ) {
      setInprocess(true);
      let thumbnailImageCompress;

      var formdata = new FormData();
      formdata.append("c_video", videoDetail.video[0]);
      formdata.append("title", videoDetail.title.trim());
      formdata.append("description", descriptionRef.current.getContent());
      var arr = [];
      for (var i = 0; i < videoDetail.files.length; i++) {
        formdata.append(`c_files[${i}][name]`, videoDetail.files[i].name);
        formdata.append(`c_files[${i}][file]`, videoDetail.files[i].file[0]);
      }
      for (var i = 0; i < videoDetail.categories.length; i++) {
        formdata.append("categories[]", videoDetail.categories[i].id);
      }
      for (var i = 0; i < videoDetail.tags.length; i++) {
        formdata.append("tag[]", videoDetail.tags[i].id);
      }
      for (var i = 0; i < videoDetail.speakers.length; i++) {
        formdata.append("speaker[]", videoDetail.speakers[i].id);
      }
      for (var i = 0; i < subcategories.length; i++) {
        formdata.append("subcategories[]", subcategories[i]);
      }

      // for (var i = 0; i < videoDetail.group_ids.length; i++) {
      //   formdata.append("group_ids[]", videoDetail.group_ids[i].id);
      // }
      for (var i = 0; i < videoDetail.eventIds.length; i++) {
        formdata.append("eventIds[]", videoDetail.eventIds[i].id);
      }
      formdata.append("clif_notes[]", editdescriptionRef.current.getContent());
      for (var i = 0; i < partner.length; i++) {
        formdata.append(`partners[${i}][name]`, partner[i].name);
        if (partner[i].logo[0] !== undefined && partner[i].logo[0] !== null) {
          formdata.append(`partners[${i}][havelogo]`, true);
        } else {
          formdata.append(`partners[${i}][havelogo]`, false);
        }
        formdata.append(`partners[${i}][url]`, partner[i].url);
      }

      for (let i = 0; i < partnerImgData.length; i++) {
        try {
          const imageCompress = await CompressImageFunction(partnerImgData[i]);
          formdata.append(`partners[${i}][logo]`, imageCompress);
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      }

      if (videoDetail.thumbnail !== undefined) {
        if (thumbnailCroppedFileData) {
          thumbnailImageCompress = await CompressImageFunction(
            thumbnailCroppedFileData
          );
        }
        formdata.append("thumbnail", thumbnailImageCompress);
      }
      // if (selectedAllGroup) {
      //   for (var i = 0; i < allgroup.length; i++) {
      //     formdata.append("group_ids[]", allgroup[i]._id);
      //   }
      // }
      //formdata.append("eventFor", videoDetail.eventFor);
      formdata.append("starting_view_cnt", videoDetail.starting_view_cnt);
      if (videoDetail.upload_date) {
        formdata.append("upload_date", videoDetail.upload_date);
      } else {
        formdata.append("upload_date", new Date());
      }
      formdata.append("makeFeaturedCheckbox", videoDetail.makeFeaturedCheckbox);

      dataFromRules.event_id &&
        dataFromRules.event_id.length > 0 &&
        formdata.append(`restrictedAccessEventId[]`, dataFromRules.event_id[0]);

      dataFromRules.group_id.length > 0 &&
        dataFromRules.group_id.map((id) => {
          formdata.append(`restrictedAccessGroupId[]`, id);
        });

      dataFromRules.membership_plan_id.length > 0 &&
        dataFromRules.membership_plan_id.map((id) => {
          formdata.append(`restrictedAccessMembershipPlanId[]`, id);
        });
      if (dataFromRules?.tags_id) {
        dataFromRules?.tags_id?.length > 0 &&
          dataFromRules?.tags_id?.map((id) => {
            formdata.append(`restrictedAccessTagId[]`, id);
          });
      }
      dataFromRules.userAccess.length !== 0 &&
        formdata.append(
          `restrictionAccess`,
          dataFromRules?.userAccess === "all" ? "public" : "restricted"
        );

      dataFromRules.userAccess === "restricted" &&
        dataFromRules.user_id.length > 0 &&
        dataFromRules.user_id.map((id) => {
          formdata.append(`restrictedAccessUserId[]`, id);
        });

      dispatch({ type: "SETINPROCESS" });
      axios
        .post(process.env.REACT_APP_API_URL + "video/create", formdata, {
          headers: authAdminHeader(),
          cancelToken: new CancelToken(
            (cancel) => (cancelUpload.current = cancel)
          ),
          onUploadProgress: (progressEvent) => {
            setshowalert(true);
            let percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setprogbarVal(percentCompleted + "%");
            setalertmsg("In Process... " + percentCompleted + "%");
            if (percentCompleted === 100) {
              setalertmsg("Processing...");
              setuploadInprocess(true);
            }
          },
        })
        .then((res) => {
          if (res.data.invalidToken) {
            localStorage.clear();
            history("/adminlogin");
          }
          setuploadInprocess(false);
          setabortvideoId(res.data.data._id);
          if (!abortProcess) {
            setcompressInprocess(true);
            setalertmsg("Compressing...");
            axios
              .post(
                process.env.REACT_APP_API_URL + "video/compress",
                { id: res.data.data._id, video_v: res.data.data.video },
                {
                  headers: authAdminHeader(),
                }
              )
              .then((res) => {
                setInprocess(false);
                setabortProcess(false);
                setabortvideoId("");
                setshowalert(true);
                setalertmsg(res.data.message);
                history(`/contentlibrary/listing/noid/0`);
                setTimeout(() => {
                  setshowalert(false);
                  setalertmsg("");
                }, 3000);
                setcompressInprocess(false);
                dispatch({ type: "REMOVEINPROCESS" });
                handleClearAllStates();
              })
              .catch((e) => {
                dispatch({ type: "REMOVEINPROCESS" });
                handleClearAllStates();
                setInprocess(false);
                setshowalert(false);
                setalertmsg("");
                console.log(e);
              });
          }
        })
        .catch((e) => {
          if (axios.isCancel) {
          } else {
            dispatch({ type: "REMOVEINPROCESS" });
            handleClearAllStates();
            setInprocess(false);
            setshowalert(false);
            setalertmsg("");
          }
          console.log(e);
        });
    } else {
      var obj = { ...videoDetailErr };
      if (videoDetail.video.length === 0)
        obj = { ...obj, video: "Select video!" };
      if (videoDetail.categories.length === 0)
        obj = { ...obj, categories: "Select category!" };
      if (videoDetail.title.length === 0)
        obj = { ...obj, title: "Enter title!" };
      if (!checkDesciptionValue(editorState))
        obj = { ...obj, description: "Enter description!" };
      if (Object.keys(obj).length > 0) {
        const emptyKeys = Object.keys(obj).filter((key) => obj[key] !== "");
        const inputElement = document.getElementsByName(emptyKeys[0])[0];
        if (inputElement) {
          inputElement.focus(); // Focus the input element
        }
      }
      setvideoDetailErr({ ...obj });
      noRuleFieldSelected && setRuleError(true);
    }
  };

  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  //on change for Edit
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setAddSpeaker({ ...addSpeaker, [e.target.name]: e.target.value });
    if (e.target.value.length > 0) {
      setErr({ ...err, [e.target.name]: "" });
    }
  };

  //addspeaker api function
  async function addSpeakerApi() {
    const addSpeakerFormData = new FormData();
    if (addSpeaker.speakerIcon !== null) {
      addSpeakerFormData.append("profileImg", addSpeaker.speakerIcon);
    }
    addSpeakerFormData.append("name", addSpeaker.name);
    addSpeakerFormData.append("firstName", addSpeaker.firstName);
    addSpeakerFormData.append("lastName", addSpeaker.lastName);
    addSpeakerFormData.append(
      "display_name",
      addSpeaker.displayName && addSpeaker.displayName !== ""
        ? addSpeaker.displayName
        : addSpeaker.firstName !== "" || addSpeaker.lastName !== ""
          ? `${addSpeaker.firstName} ${addSpeaker.lastName}`.trim()
          : ""
    );
    addSpeakerFormData.append("email", addSpeaker.email);
    addSpeakerFormData.append("company", addSpeaker.company);
    addSpeakerFormData.append("phone", addSpeaker.phone);
    addSpeakerFormData.append("facebook", addSpeaker.facebook);
    addSpeakerFormData.append("linkedin", addSpeaker.linkedin);
    addSpeakerFormData.append("profession", addSpeaker.profession);
    const addSpeakerRes = await authService.createSpeakerInUserChanges(
      addSpeakerFormData
    );
    try {
      if (addSpeakerRes.data.status) {
        setAddInprocess(false);
        setExistingModalAlert(addSpeakerRes.data.message);
        Services.getSpeakerList()
          .then((res) => {
            if (res.data.status) {
              setallspeakers(res.data.data);
            }
          })
          .catch((e) => {
            console.log(e);
          });
        setAddSpeaker({
          profilePic: "",
          guestIcon: "",
          partnerIcon: "",
          speakerIcon: "",
          name: "",
          firstName: "",
          lastName: "",
          email: "",
          company: "",
          profession: "",
          phone: "",
          auth0Id: "",
          facebook: "",
          linkedin: "",
          passcode: "",
          displayName: "",
        });
        setTimeout(() => {
          setExistingModalAlert("");
        }, 2000);
      } else {
        Services.getSpeakerList()
          .then((res) => {
            if (res.data.status) {
              setallspeakers(res.data.data);
            }
          })
          .catch((e) => {
            console.log(e);
          });
        setAddSpeaker({
          profilePic: "",
          guestIcon: "",
          partnerIcon: "",
          speakerIcon: "",
          name: "",
          firstName: "",
          lastName: "",
          email: "",
          company: "",
          profession: "",
          phone: "",
          auth0Id: "",
          facebook: "",
          linkedin: "",
          passcode: "",
          displayName: "",
        });
        setAddInprocess(false);
        setExistingModalAlert(addSpeakerRes.data.message);
        setTimeout(() => {
          setExistingModalAlert("");
        }, 2000);
      }
    } catch (error) {
      setAddInprocess(false);
      setExistingModalAlert(error.data.message);
      return false;
    }
  }

  function isValidEmail(email) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  //handle add speaker
  const handleAddSpeakerSubmit = async () => {
    var status = true;
    var temp = {};
    if (addSpeaker.name.trim().length === 0) {
      temp = { ...temp, name: "Enter name!" };
      status = false;
    }
    if (addSpeaker.email.trim().length === 0) {
      temp = { ...temp, email: "Enter email!" };
      status = false;
    } else if (!isValidEmail(addSpeaker.email)) {
      temp = { ...temp, email: "Invalid email format!" };
      status = false;
    }
    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    if (status) {
      setAddInprocess(true);
      addSpeakerApi();
    } else {
      setErr(temp);
    }
  };

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  // handle create tag
  const handleCreate = () => {
    if (addtag.length > 0) {
      setcreateInprocess(true);

      Services.createtag({
        name: addtag.trim(),
      })
        .then((res) => {
          setcreateInprocess(false);
          if (res.data.status) {
            setshowalertAdd(true);
            setaddtag("");
            setalertmsgAdd(res.data.message);
            Services.getalltags_byadmin("")
              .then((res) => {
                if (res.data.status) {
                  setalltags(res.data.data);
                }
              })
              .catch((e) => {
                console.log(e);
              });
            setTimeout(() => {
              setshowalertAdd(false);
              setalertmsgAdd("");
            }, 3000);
          } else {
            setshowalertAdd(true);
            setalertmsgAdd(res.data.message);
            setTimeout(() => {
              setshowalertAdd(false);
              setalertmsgAdd("");
            }, 3000);
          }
          handleClearAllStates();
        })
        .catch((e) => {
          handleClearAllStates();
          setcreateInprocess(false);
          console.log(e);
        });
      setadderr("");
    } else {
      setadderr("Enter tags!");
    }
  };

  // handle tag onchange
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setaddtag(e.target.value.trim());
    if (e.target.value.length > 0) {
      setadderr("");
    } else {
      setadderr("Enter tags! ");
    }
  };

  // thumbnail image upload function
  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
  };

  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          {modalOpen && (
            <ImageCropperReact
              file={thumbnail}
              setModalOpen={setModalOpen}
              setFile={setThumbnail}
              fileName={fileName}
              fileDimension={fileDimension}
              requiredImageSize={requiredImageSize}
              selectImageType={selectImageType}
              requiredImageAspectRatio={requiredImageAspectRatio}
              setRelevantPartnerImg={(img) => setaddpartner({ ...addpartner, logo: img })}
              setVideoThumbnailImg={(img) => setvideoDetail({ ...videoDetail, thumbnail: img })}
              setPartnerImgData={(imgData) => setPartnerImgData([...partnerImgData, imgData])}
              setVideoThumbnailData={(imgData) => setThumbnailCroppedFileData(imgData)}
              isLoading={isLoading}
            // setRequiredImageSizeError={() => setThumimgsize(true)}
            />
          )}
          <div className="uic-title">
            <h3
              onClick={() => {
                if (modalInProcess) {
                  if (videoInprocess) {
                    setshowPopup(true);
                    setchangePage("/contentlibrary/listing/noid/0");
                  }

                  dispatch({ type: "SETCUSTOMPAGECHANGE" });
                  dispatch({
                    type: "NAVIGATEPAGE",
                    payload: "/contentlibrary/listing/noid/0",
                  });
                } else {
                  if (videoInprocess) {
                    setshowPopup(true);
                    setchangePage("/contentlibrary/listing/noid/0");
                  }
                  history(`/contentlibrary/listing/noid/0`);
                }
              }}
            >
              <img src={VideoDetailBAck} />
              Back
            </h3>
          </div>

          <h1>Add video</h1>
          {showalert && <div className="alert alert-info">{alertmsg}</div>}
          <div className="row">
            <div className="col-12 form-group">
              <span className="error ">{videoDetailErr.video}</span>
            </div>
          </div>

          <Dropzone
            onDrop={(acceptedFiles, fileRejections) => {
              dispatch({ type: "SETMODALINPROCESS" });
              fileRejections.forEach((file) => {
                file.errors.forEach((err) => {
                  if (err.code === "file-too-large") {
                    setvideoDetailErr({
                      ...videoDetailErr,
                      video: err.message,
                    });
                  }
                });
              });
              if (fileRejections.length === 0) {
                dispatch({ type: "SETMODALINPROCESS" });
                setvideoDetailErr({ ...videoDetailErr, video: "" });
                settempvideo(URL.createObjectURL(acceptedFiles[0]));
                setvideoDetail({ ...videoDetail, video: acceptedFiles });
              }
            }}
            name="video"
            multiple={false}
            accept="video/gif, video/mov, video/mp4, video/x-m4v, video/*"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <div className="group-info-user">
                  <div className="cstm-video-upload">
                    {videoDetail.video.length > 0 ? (
                      <div className="cstm-video-upload-video">
                        <video
                          className="addcontentvideo"
                          src={tempvideo}
                          controls
                        />
                      </div>
                    ) : (
                      <div className="cstm-video-upload-img">
                        <img src={Playicon} className="w-auto h-auto " />
                      </div>
                    )}
                  </div>
                  <div className="group-info-user-link">
                    <div className="cover-image-upload-ttl">
                      Drag & drop or click to add video*
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>

          <div className="cstm-add-video-title-sub">Video information</div>

          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Video title*</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="title"
                  value={videoDetail.title}
                  onChange={handleChange}
                />
              </div>
              <span className="error">{videoDetailErr.title}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="fvideo-description-edit-title">
                Video description*
              </div>
              <div className="form-group-ctst">
                <div className="cstm-vd">
                  <TinyEditor
                    value={editorState}
                    valueRef={descriptionRef}
                    handleEditorChange={(value, editor) => {
                      setvideoDetailErr({ ...videoDetailErr, description: "" });
                    }}
                    handleKeyPress={() => {
                      dispatch({ type: "SETMODALINPROCESS" });
                    }}
                  />
                </div>
              </div>
              <span className="error error-video">
                {videoDetailErr.description}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <div className="form-group-label">Video starting views</div>
              <div className="form-group-ct">
                <input
                  type="number"
                  name="starting_view_cnt"
                  value={videoDetail.starting_view_cnt}
                  onChange={handleChange}
                  min="0"
                />
              </div>
              <span className="error">{videoDetailErr.starting_view_cnt}</span>
            </div>
            <div className="col-sm-6 form-group">
              <div className="form-group-label">Date uploaded</div>
              <div className="form-group-ct">
                <input
                  type="date"
                  name="upload_date"
                  value={
                    videoDetail.upload_date
                      ? videoDetail.upload_date.split("T")[0]
                      : ""
                  }
                  onChange={handleChange}
                  min={moment("2000-01-01").format("YYYY-MM-DD")}
                  max={new Date().toISOString().split("T")[0]}
                />
              </div>
              <span className="error ">{videoDetailErr.upload_date}</span>
            </div>
          </div>

          {/* <div className="cstm-add-video-title-sub">Select event</div>
          <div className="col-sm-12 form-group mb-0">
            <div className="form-group-ctst">

              {
                events.length > 0 && events.map(event_ =>
                  <>
                    <label className="subcat-addvideo">
                      <input
                        type="checkbox"
                        name="eventFor"
                        value={event_.name}
                        onChange={(e) => {
                          dispatch({ type: "SETMODALINPROCESS" })
                          if (e.target.checked) {
                            setvideoDetail({ ...videoDetail, eventFor: event_.name });
                          } else {
                            setvideoDetail({ ...videoDetail, eventFor: "" });
                          }
                        }}
                        checked={videoDetail.eventFor === event_.name}
                      />
                      {event_.name}
                    </label>
                  </>
                )
              }


            </div>
          </div> */}
          <div className="cstm-add-video-title-sub">Category</div>

          <p>
            Make it easier to find this video by adding the right categories.
          </p>
          <div className="row">
            <div className="col-sm-12 form-group mb-0">
              <div className="form-group-label">Select category*</div>
              <div className="form-group-ctst">
                <input
                  className=""
                  type="text"
                  list="categories"
                  name="browser"
                  onKeyUp={(e) => {
                    dispatch({ type: "SETMODALINPROCESS" });
                    var selcate = allcategories.filter((cate) => {
                      if (cate.name === e.target.value) return cate;
                    });
                    var vidcat = videoDetail.categories.filter((category) => {
                      if (category.name === e.target.value) return category;
                    });
                    if (selcate.length > 0 && vidcat.length === 0) {
                      setvideoDetail({
                        ...videoDetail,
                        categories: [
                          ...videoDetail.categories,
                          {
                            id: selcate[0]._id,
                            name: selcate[0].name,
                            subcategory: selcate[0].subcategory,
                          },
                        ],
                      });
                      // setsubcategories([
                      //   ...subcategories,
                      //   ...selcate[0].subcategory.map((cat) => {
                      //     return cat._id;
                      //   }),
                      // ]);
                      e.target.value = "";
                    }
                    setvideoDetailErr({ ...videoDetailErr, categories: "" });
                  }}
                />
              </div>
              <datalist id="categories">
                {allcategories.length > 0
                  ? allcategories.map((cate) => {
                    if (
                      videoDetail.categories.filter((vcat) => {
                        if (vcat.name === cate.name) return vcat;
                      }).length === 0
                    )
                      return <option>{cate.name}</option>;
                  })
                  : ""}
              </datalist>
              <span className="error error-video">
                {videoDetailErr.categories}
              </span>
            </div>
          </div>

          <div>
            {videoDetail.categories.length > 0
              ? videoDetail.categories.map((cate) => {
                return (
                  <span className="topic-label-in">
                    <b>{cate.name}</b>
                    <button
                      onClick={() => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        const sub_category = videoDetail.categories
                          .filter((category) => {
                            if (category.id === cate.id) return category;
                          })
                          .map((cat) => {
                            return cat.subcategory.map((subcat) => {
                              return subcat._id;
                            });
                          });
                        console.log(sub_category, "subcateg893279487289374");
                        setvideoDetail({
                          ...videoDetail,
                          categories: videoDetail.categories.filter(
                            (category) => {
                              if (category.id !== cate.id) return category;
                            }
                          ),
                        });
                        setsubcategories([
                          ...subcategories.filter((subcat) => {
                            if (
                              sub_category.filter((subid) => {
                                if (subid.includes(subcat)) return subid;
                              }).length === 0
                            )
                              return subcat;
                          }),
                        ]);
                      }}
                    >
                      ×
                    </button>
                  </span>
                );
              })
              : ""}
          </div>
          {videoDetail.categories.filter((cat) => {
            if (cat.subcategory) return cat;
          }).length > 0 ? (
            <div className="row">
              <div className="col-sm-12 form-group mb-0">
                <div class="cstm-add-video-title-sub">Select subcategory</div>

                <div className="form-group-ctst">
                  {videoDetail.categories.map((cat) => {
                    if (cat.subcategory)
                      return cat.subcategory.map((vcat) => {
                        return (
                          <label className="subcat-addvideo">
                            <input
                              type="checkbox"
                              name="subcategory"
                              value={vcat.name}
                              onChange={(e) => {
                                dispatch({ type: "SETMODALINPROCESS" });
                                if (e.target.checked) {
                                  setsubcategories([
                                    ...subcategories,
                                    vcat._id,
                                  ]);
                                } else {
                                  setsubcategories([
                                    ...subcategories.filter((cat) => {
                                      if (cat !== vcat._id) return cat;
                                    }),
                                  ]);
                                }
                              }}
                              defaultChecked={
                                subcategories.filter((cat) => {
                                  if (cat === vcat._id) return cat;
                                }).length > 0
                              }
                            />
                            {vcat.name}{" "}
                          </label>
                        );
                      });
                    else return <></>;
                  })}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="cstm-add-video-title-sub">Video tags</div>
          <p>Make it easier to find this video by adding the right tags.</p>
          <div className="row">
            <div className="col-sm-12 form-group mb-0">
              <div className="form-group-label">
                Select tags or{" "}
                <a
                  className="create-speaker-link"
                  data-toggle="modal"
                  data-target="#addtag"
                >
                  Create Tag
                </a>
              </div>
              <div className="form-group-ctst">
                <input
                  autoComplete="off"
                  className=""
                  type="text"
                  list="tags"
                  name="browser"
                  onKeyUp={(e) => {
                    dispatch({ type: "SETMODALINPROCESS" });
                    var seltag = alltags.filter((tag) => {
                      if (tag.name === e.target.value) return tag;
                    });
                    var vidtag = videoDetail.tags.filter((tag) => {
                      if (tag.name === e.target.value) return tag;
                    });
                    if (seltag.length > 0 && vidtag.length === 0) {
                      setvideoDetail({
                        ...videoDetail,
                        tags: [
                          ...videoDetail.tags,
                          { id: seltag[0]._id, name: seltag[0].name },
                        ],
                      });
                      e.target.value = "";
                    }
                  }}
                />
              </div>
              <datalist id="tags">
                {alltags.length > 0
                  ? alltags.map((tag) => {
                    if (
                      videoDetail.tags.filter((vtag) => {
                        if (vtag.name === tag.name) return vtag;
                      }).length === 0
                    )
                      return <option>{tag.name}</option>;
                  })
                  : ""}
              </datalist>
            </div>
          </div>
          <div>
            {videoDetail.tags !== null &&
              videoDetail.tags !== undefined &&
              videoDetail.tags.length > 0
              ? videoDetail.tags.map((tag) => {
                return (
                  <span className="topic-label-in">
                    <b>{tag.name}</b>
                    <button
                      onClick={() => {
                        setvideoDetail({
                          ...videoDetail,
                          tags: videoDetail.tags.filter((vtag) => {
                            if (tag.id !== vtag.id) return vtag;
                          }),
                        });
                      }}
                    >
                      ×
                    </button>
                  </span>
                );
              })
              : ""}
          </div>
          <div className="cstm-add-video-title-sub">Speakers</div>
          <p>Make videos easier to find by adding speaker information.</p>
          <div className="row">
            <div className="col-sm-12 form-group mb-0">
              <div className="form-group-label">
                Select speaker or{" "}
                <a
                  className="create-speaker-link"
                  data-toggle="modal"
                  data-target="#editAttendee"
                >
                  Create speaker
                </a>
              </div>
              <div className="form-group-ctst">
                <input
                  className=""
                  type="text"
                  list="speakers"
                  name="browser"
                  onKeyUp={(e) => {
                    dispatch({ type: "SETMODALINPROCESS" });
                    var selspeak = allspeakers.filter((speak) => {
                      if (
                        (speak.first_name !== "" || speak.last_name !== "") &&
                        `${speak.first_name} ${speak.last_name}` ===
                        e.target.value
                      )
                        return speak;
                    });
                    if (selspeak.length > 0) {
                      setvideoDetail({
                        ...videoDetail,
                        speakers: [
                          ...videoDetail.speakers,
                          {
                            id: selspeak[0]._id,
                            name: `${selspeak[0].first_name} ${selspeak[0].last_name}`,
                            display_name: `${selspeak[0].display_name}`,
                          },
                        ],
                      });
                      e.target.value = "";
                    }
                  }}
                />
              </div>
              <datalist id="speakers">
                {allspeakers.length > 0
                  ? allspeakers.map((speaker) => {
                    const normalizedSpeakerName = `${speaker.first_name} ${speaker.last_name}`;
                    if (
                      videoDetail.speakers.filter((speak) => {
                        if (speak.name === normalizedSpeakerName)
                          return speak;
                      }).length === 0
                    )
                      return (
                        <option
                          value={`${speaker.first_name} ${speaker.last_name}`}
                        >
                          ({speaker.PreferredEmail})
                        </option>
                      );
                  })
                  : ""}
              </datalist>
            </div>
          </div>
          <div>
            {videoDetail.speakers !== null &&
              videoDetail.speakers !== undefined &&
              videoDetail.speakers.length > 0
              ? videoDetail.speakers.map((speaker) => {
                return (
                  <span className="topic-label-in">
                    <b>{speaker.name}</b>
                    <button
                      onClick={() => {
                        setvideoDetail({
                          ...videoDetail,
                          speakers: videoDetail.speakers.filter((speak) => {
                            if (speaker.id !== speak.id) return speak;
                          }),
                        });
                      }}
                    >
                      ×
                    </button>
                  </span>
                );
              })
              : ""}
          </div>
          {/* <div className="cstm-add-video-title-sub">Groups</div>

          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Groups</div>
              <div>
                <input
                  className=""
                  type="text"
                  list="groupsVideo"
                  name="browser"
                  onKeyUp={(e) => {
                    dispatch({ type: "SETMODALINPROCESS" })
                    if (e.target.value !== "All") {
                      var selgrp = allgroup.filter((grp) => {
                        if (grp.groupTitle === e.target.value) return grp;
                      });
                      var vidgrp = videoDetail.group_ids.filter((grp) => {
                        if (grp.name === e.target.value) return grp;
                      });
                      if (selgrp.length > 0 && vidgrp.length === 0) {

                        setvideoDetail({
                          ...videoDetail,
                          group_ids: [
                            ...videoDetail.group_ids,
                            { id: selgrp[0]._id, name: selgrp[0].groupTitle },
                          ],
                        });
                        e.target.value = "";
                      }
                    } else {
                      setselectedAllGroup(true);
                      setvideoDetail({
                        ...videoDetail,
                        group_ids: [],
                      });
                    }
                  }}
                />
              </div>
              <datalist id="groupsVideo">
                <option>All</option>
                {allgroup.length > 0 && !selectedAllGroup
                  ? allgroup.map((grp) => {
                    return <option>{grp.groupTitle}</option>;
                  })
                  : ""}
              </datalist>
            </div>
          </div>
          <div>
            {selectedAllGroup ? (
              <span className="topic-label-in">
                <b>All</b>
                <button
                  onClick={() => {
                    dispatch({ type: "SETMODALINPROCESS" })
                    setselectedAllGroup(false);
                  }}
                >
                  ×
                </button>
              </span>
            ) : (
              <></>
            )}
            {videoDetail.group_ids.length > 0
              ? videoDetail.group_ids.map((grp) => {
                return (
                  <span className="topic-label-in">
                    <b>{grp.name}</b>
                    <button
                      onClick={() => {
                        dispatch({ type: "SETMODALINPROCESS" })
                        setvideoDetail({
                          ...videoDetail,
                          group_ids: videoDetail.group_ids.filter((group) => {
                            if (group.id !== grp.id) return group;
                          }),
                        });
                      }}
                    >
                      ×
                    </button>
                  </span>
                );
              })
              : ""}
          </div> */}
          <div className="cstm-add-video-title-sub">
            Select Event for reference
          </div>

          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Events</div>
              <div>
                <input
                  className=""
                  type="text"
                  list="eventsVideo"
                  name="browser"
                  autoComplete="off"
                  onKeyUp={(e) => {
                    dispatch({ type: "SETMODALINPROCESS" }); // Dispatch Redux action to set modal in process
                    const inputValue = e.target.value.trim();
                    // Filter allEvents based on trimmed input value
                    const selectedEvent = allEvents.find(
                      (event) => event.title.trim() === inputValue
                    );
                    if (
                      selectedEvent &&
                      !videoDetail.eventIds.some(
                        (event) => event.id === selectedEvent._id
                      )
                    ) {
                      setvideoDetail({
                        ...videoDetail,
                        eventIds: [
                          ...videoDetail.eventIds,
                          { id: selectedEvent._id, name: selectedEvent.title },
                        ],
                      });
                      e.target.value = ""; // Clear input field after selecting event
                    }
                  }}
                  onChange={(e) => {
                    dispatch({ type: "SETMODALINPROCESS" }); // Dispatch Redux action to set modal in process
                    const selectedOption = e.target.value;
                    const selectedEvent = allEvents.find(
                      (event) => event.title === selectedOption
                    );
                    if (
                      selectedEvent &&
                      !videoDetail.eventIds.some(
                        (event) => event.id === selectedEvent._id
                      )
                    ) {
                      setvideoDetail({
                        ...videoDetail,
                        eventIds: [
                          ...videoDetail.eventIds,
                          { id: selectedEvent._id, name: selectedEvent.title },
                        ],
                      });
                      e.target.value = "";
                    }
                  }}
                />
              </div>
              <datalist id="eventsVideo">
                {allEvents.length > 0
                  ? allEvents.map((event) => {
                    if (
                      videoDetail.eventIds.filter((veve) => {
                        if (veve.name === event.title) return veve;
                      }).length === 0
                    )
                      return <option>{event.title}</option>;
                  })
                  : ""}
              </datalist>
            </div>
          </div>
          <div>
            {videoDetail.eventIds.length > 0
              ? videoDetail.eventIds.map((event) => {
                return (
                  <span className="topic-label-in">
                    <b>{event.name}</b>
                    <button
                      onClick={() => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        setvideoDetail({
                          ...videoDetail,
                          eventIds: videoDetail.eventIds.filter(
                            (innerEvnt) => {
                              if (innerEvnt.id !== event.id) return innerEvnt;
                            }
                          ),
                        });
                      }}
                    >
                      ×
                    </button>
                  </span>
                );
              })
              : ""}
          </div>

          {/* groups */}

          <div className="form-group">
            <label className="subcat-addvideo">
              <input
                type="checkbox"
                name="makeFeaturedCheckbox"
                onChange={(e) => {
                  dispatch({ type: "SETMODALINPROCESS" });
                  setvideoDetail({
                    ...videoDetail,
                    makeFeaturedCheckbox: e.target.checked,
                  });
                }}
                checked={videoDetail.makeFeaturedCheckbox}
              />
              Add to news feed
            </label>
          </div>
          <div className="profile-detilas-all-nav cstm-profile-details-all-nav">
            <ul className="nav profiledt-menu cstm-tab-add-video">
              <li>
                <a
                  className="active cstm-profile-link"
                  id="Relevant-partner"
                  data-toggle="tab"
                  href="#relevantpartner"
                  role="tab"
                >
                  Relevant partners
                </a>
              </li>
              <li>
                <a
                  className="cstm-profile-link"
                  id="Clif-notes"
                  data-toggle="tab"
                  href="#clifnotes"
                  role="tab"
                >
                  Cliff Notes
                </a>
              </li>
              <li>
                <a
                  className="cstm-profile-link"
                  id="video-files"
                  data-toggle="tab"
                  href="#videofiles"
                  role="tab"
                >
                  Files
                </a>
              </li>
              <li>
                <a
                  className="cstm-profile-link"
                  id="video-thumbnail"
                  data-toggle="tab"
                  href="#thumbnail"
                  role="tab"
                >
                  Thumbnail
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content profile-details-tabs" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="relevantpartner"
              role="tabpanel"
            >
              <div className="cstm-add-video-title-sub">Relevant partners</div>
              <p>
                Include the relevant partners for this video (you can add
                multiple partners).
                <span className="error position-relative">{err.prtn}</span>
              </p>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  setSelectImageType("relevantPartners");
                  const file = acceptedFiles[0];
                  if (file.type.startsWith("image/")) {
                    setModalOpen(true)
                    setIsLoading(true)
                    const reader = new FileReader();
                    reader.onload = function (event) {
                      const img = new Image();
                      img.onload = function () {
                        dispatch({ type: "SETMODALINPROCESS" });
                        setThumbnail(event.target.result);
                        thumbnailImages(file);
                        setIsLoading(false)
                      };
                      img.src = event.target.result;
                    };
                    reader.readAsDataURL(file);
                  }
                }}
                // onDrop={(acceptedFiles) => {
                //   dispatch({ type: "SETMODALINPROCESS" });
                //   setaddpartner({ ...addpartner, logo: acceptedFiles });
                // }}
                name="partnerimg"
                multiple={false}
                accept="image/png, image/gif, image/jpeg, image/jpg, "
                maxSizeBytes="10485760"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <div className="group-info-user">
                      <div className="group-info-user-img">
                        <img
                          className={
                            addpartner.logo.length > 0 ? "addcontentvideo" : ""
                          }
                          src={
                            addpartner.logo.length > 0 ? addpartner.logo : frame
                          }
                        />
                      </div>
                      <div className="group-info-user-link">
                        <div className="cover-image-upload-ttl">
                          Drag & drop or click to add partner logo
                        </div>
                        <div className="cover-image-upload-size">
                          JPEG, PNG, JPG or PNG , No longer then 10 MB
                        </div>
                        <div className="cover-image-upload-size">
                          Recommended size 564 x 216
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className="row">
                <div className="col-sm-8">
                  <div className="form-group-label">Partner name*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      name="partnername"
                      value={addpartner.name}
                      onChange={(e) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        setaddpartner({ ...addpartner, name: e.target.value });
                        setErr({ ...err, prtn: "" });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-8">
                  <div className="form-group-label">Partner URL</div>
                  <div className="form-group-ct">
                    <input
                      type="url"
                      name="partnerurl"
                      value={addpartner.url}
                      onChange={(e) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        if (
                          e.target.value.length > 0 &&
                          isValidHttpUrl(e.target.value) === false
                        )
                          setvideoDetailErr({
                            ...videoDetailErr,
                            url: "Invalid URL!",
                          });
                        else setvideoDetailErr({ ...videoDetailErr, url: "" });
                        setaddpartner({ ...addpartner, url: e.target.value });
                      }}
                    />
                  </div>

                  <span className="error err-url">{videoDetailErr.url}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 add-btn-align">
                  <button
                    type="button"
                    className="btn"
                    onClick={() => {
                      dispatch({ type: "SETMODALINPROCESS" });
                      if (addpartner.name.length > 0) {
                        setpartner([
                          ...partner,
                          {
                            logo: addpartner.logo,
                            name: addpartner.name,
                            url: addpartner.url,
                          },
                        ]);
                        setaddpartner({ logo: [], name: "", url: "" });
                        setErr({ ...err, prtn: "" });
                      } else {
                        setErr({ ...err, prtn: "Enter name!" });
                      }
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="row patnerdetails cstm-cn-video-prtn ">
                <div className="row">
                  {partner.map((prtn, index) => {
                    return (
                      <div className="col-2 cstm-col-cbs-support">
                        <div className="cbs-support cbs-support-cstm">
                          <button
                            className="btn-partner"
                            onClick={() => {
                              dispatch({ type: "SETMODALINPROCESS" });
                              var arr = [...partner];
                              var imageDataArr = [...partnerImgData];

                              arr.splice(index, 1);
                              imageDataArr.splice(index, 1);

                              setpartner([...arr]);
                              setPartnerImgData([...imageDataArr]);
                            }}
                          >
                            ✕
                          </button>
                          {prtn.logo.length > 0 ? (
                            <img
                              className="cstm-partner-pic"
                              src={
                                prtn.logo.length > 0 ? prtn.logo : csvsupport
                              }
                            />
                          ) : (
                            <></>
                          )}
                          <h4>{prtn.name}</h4>
                          {prtn.url && prtn.url.length > 0 ? (
                            <a
                              href={prtn.url}
                              target="_blank"
                              style={{ wordBreak: "break-all" }}
                            >
                              {prtn.url}
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="clifnotes"
              role="tabpanel"
              aria-labelledby="Groupposts-tab"
            >
              <h2>Cliff Notes</h2>
              <p>
                Summarise this video by adding a cliff note.
                <span className="error">{err.cliff}</span>
              </p>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="cstm-vd">
                    <TinyEditor
                      value={videoDetail.clif_notes}
                      valueRef={editdescriptionRef}
                      handleEditorChange={(value, editor) => {
                        // setclif_note(value)
                      }}
                      handleKeyPress={() => {
                        dispatch({ type: "SETMODALINPROCESS" });
                      }}
                    />
                  </div>
                  {/* <button
                    className="btn clif-add-btn"
                    onClick={() => {
                      // var text = stateToHTML(clif_note.getCurrentContent(), {
                      //   defaultBlockTag: null,
                      // }).replace(/&nbsp;$/, "");
                      if (
                        clif_note !== undefined
                      ) {
                        dispatch({ type: "SETMODALINPROCESS" })
                        setvideoDetail({
                          ...videoDetail,
                          clif_notes: [
                            ...videoDetail.clif_notes,
                            editdescriptionRef.current.getContent(),
                          ],
                        });
                        setclif_note("");
                        setErr({ ...err, cliff: "" });
                      } else {
                        setErr({ ...err, cliff: "Enter clif note!" });
                      }
                    }}
                  >
                    Add
                  </button> */}
                </div>
              </div>
              {/* {videoDetail.clif_notes.length > 0 &&
                videoDetail.clif_notes.map((note, index) => {
                  return (
                    <div className="cstm-clif-notes">
                      <div
                        className="cstm-clif-notes-inner"
                        dangerouslySetInnerHTML={{ __html: note }}
                      ></div>
                      <button
                        className="btn-partner cstm-close-icon"
                        onClick={() => {
                          dispatch({ type: "SETMODALINPROCESS" })
                          var arr = [...videoDetail.clif_notes];
                          arr.splice(index, 1);
                          setvideoDetail({
                            ...videoDetail,
                            clif_notes: [...arr],
                          });
                        }}
                      >
                        ✕
                      </button>
                    </div>
                  );
                })} */}
            </div>
            <div className="tab-pane fade" id="videofiles" role="tabpanel">
              <h2>Add files</h2>
              <p>
                Add related files for this video ( you can add multiple files)
                <span className="error">{err.files}</span>
              </p>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  dispatch({ type: "SETMODALINPROCESS" });

                  if (acceptedFiles[0].size >= 10000000) {
                    setaddfile({ file: "", name: "" });
                    setvideoDetailErr({
                      ...videoDetailErr,
                      fileSize: "Allow only 10 MB size",
                    });
                  } else {
                    setaddfile({
                      file: acceptedFiles,
                      name: acceptedFiles[0].name.split(".")[0],
                    });
                    setvideoDetailErr({
                      ...videoDetailErr,
                      fileSize: "",
                    });
                  }
                }}
                name="files"
                multiple={false}
                accept="image/png, image/gif, image/jpeg, image/jpg, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf, application/msword, text/csv , application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                maxSizeBytes="10485760"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <div className="group-info-user">
                      <div className="group-info-user-img">
                        <img
                          className={
                            addfile.file.length > 0 ? "addcontentvideo" : ""
                          }
                          src={
                            addfile.file.length > 0
                              ? addfile.file[0].type.indexOf("image") !== -1
                                ? URL.createObjectURL(addfile.file[0])
                                : addfile.file[0].type.indexOf("pdf") !== -1
                                  ? PdfImg
                                  : DocImg
                              : frame
                          }
                        />
                      </div>
                      <div className="group-info-user-link">
                        <div className="cover-image-upload-ttl">
                          Drag & drop or click to add files
                        </div>
                        <div className="cover-image-upload-size">
                          JPEG, PNG, JPG, XLS, PDF, PPT or DOC, No longer then
                          10 MB
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
              <span className="error position-relative mt-0">
                {videoDetailErr.fileSize}
              </span>
              <div className="row">
                <div className="col-sm-8">
                  <div className="form-group-label">File name</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      name="filename"
                      value={addfile.name}
                      onChange={(e) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        setaddfile({ ...addfile, name: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 add-btn-align">
                  <button
                    type="button"
                    className="btn"
                    onClick={() => {
                      if (
                        addfile.file.length > 0 &&
                        videoDetailErr.fileSize.length <= 0
                      ) {
                        dispatch({ type: "SETMODALINPROCESS" });
                        setvideoDetail({
                          ...videoDetail,
                          files: [...videoDetail.files, addfile],
                        });
                        setaddfile({ name: "", file: [] });
                        setErr({ ...err, files: "" });
                      } else if (videoDetailErr.fileSize.length > 0) {
                        setvideoDetailErr({
                          ...videoDetailErr,
                          fileSize: "Allow only 10 MB size",
                        });
                      } else {
                        setErr({ ...err, files: "Select file!" });
                      }
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              <br />
              <div className="cstm-vd-box-in22">
                {videoDetail.files.map((file, index) => {
                  return (
                    <div className="cstm-vd-box-in-main">
                      <button
                        className="cstm-vd-box-close"
                        onClick={() => {
                          var arr = [...videoDetail.files];
                          arr.splice(index, 1);
                          setvideoDetail({
                            ...videoDetail,
                            files: [...arr],
                          });
                        }}
                      >
                        ✖
                      </button>
                      { }
                      <img
                        src={
                          file.file[0].type.indexOf("image") !== -1
                            ? URL.createObjectURL(file.file[0])
                            : file.file[0].type.indexOf("pdf") !== -1
                              ? PdfImg
                              : DocImg
                        }
                      />
                      <h4>{file.name}</h4>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="tab-pane fade" id="thumbnail" role="tabpanel">
              <h2>Thumbnail</h2>
              <p>Upload a thumbnail image for this video</p>
              {thumimgsize && (
                <span className="error2">
                  {`Recommended size ${requiredImageSize.width} x ${requiredImageSize.height}`}
                </span>
              )}
              <Dropzone
                onDrop={(acceptedFiles) => {
                  setSelectImageType("thumbnail");
                  const file = acceptedFiles[0];
                  if (file.type.startsWith("image/")) {
                    setModalOpen(true)
                    setIsLoading(true)
                    const reader = new FileReader();
                    reader.onload = function (event) {
                      const img = new Image();
                      img.onload = function () {
                        dispatch({ type: "SETMODALINPROCESS" });
                        setThumbnail(event.target.result);
                        thumbnailImages(file);
                        setThumimgsize(false)
                        setIsLoading(false)
                      };
                      img.src = event.target.result;
                    };
                    reader.readAsDataURL(file);
                  }
                }}
                name="thumbnail"
                multiple={false}
                maxSizeBytes="1042880"
                accept="image/png, image/gif, image/jpeg, image/jpg"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <div className="group-info-user">
                      <div className="group-info-user-img">
                        {videoDetail.thumbnail !== undefined &&
                          videoDetail.thumbnail.length > 0 ? (
                          <img src={videoDetail.thumbnail} />
                        ) : (
                          <img src={frame} />
                        )}
                      </div>
                      <div className="group-info-user-link">
                        <div className="cover-image-upload-ttl">
                          Drag & drop or click to upload a thumbnail image for
                          this video
                        </div>
                        <div className="cover-image-upload-size">
                          JPEG, PNG, JPG or PNG , No longer then 10 MB
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>

          <AddRules
            title="Rules*"
            ruleError={ruleError}
            totalUserList={totalUserList}
            setRuleError={setRuleError}
            noRuleFieldSelected={noRuleFieldSelected}
            setNoRuleFieldSelected={setNoRuleFieldSelected}
            dataFromRules={dataFromRules}
            setDataFromRules={setDataFromRules}
            showTagsField={true}
            users={users}
            setUsers={setUsers}
            emails={emails}
            setEmails={setEmails}
            invalidEmail={invalidEmail}
            setInvalidEmail={setInvalidEmail}
            emailRegex={emailRegex}
            nonUsers={nonUsers}
            setNonUsers={setNonUsers}
          />
          <div className="cyp-btm-btn cstm-lb-only">
            {Inprocess ? (
              <div className="loader-button1">
                <button>
                  {uploadInprocess
                    ? "Processing..."
                    : compressInprocess
                      ? "Compressing..."
                      : "In Process... " + progbarVal}
                </button>
                <span className="loader-btn">
                  <img src={Loader2} />
                </span>
              </div>
            ) : (
              <button
                type="button"
                className="btn"
                onClick={handlePublish}
                disabled={Inprocess}
              >
                Publish
              </button>
            )}
            <button
              className="btn2"
              onClick={() => {
                handleClearAllStates();
                if (cancelUpload.current)
                  cancelUpload.current("Upload cancelled");
                setabortProcess(true);
                if (abortvideoId)
                  Services.permenantdeletevideobyid(abortvideoId);
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/contentlibrary/listing/noid/0");
                } else {
                  history(`/contentlibrary/listing/noid/0`);
                }
              }}
            >
              Discard
            </button>
          </div>
        </div>
        {showPopup && (
          <div className="desk-globle-model-box globle-model-box ">
            <div className="desk-globle-model-box-inner cstm-deactivate-account-only">
              <div className="cstm-deactivate-account-main">
                <div class="cstm-deactivate-account-main-title">
                  Video upload is still in progress.
                  <br />
                  Are you sure you want to leave?
                </div>

                <div className="ays-cancel-btn">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (reloadPage) window.location.reload(reloadPage);
                      setshowPopup(false);
                      dispatch({ type: "REMOVEINPROCESS" });
                      history(changePage);
                    }}
                  >
                    Yes
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setshowPopup(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>{" "}
          </div>
        )}
      </div>
      <div
        className="modal fade edit-user-details-popup"
        id="editAttendee"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Add Speaker</h2>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="editAttendeeModelId"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {existingModalAlert && (
                <div className="alert alert-info">{existingModalAlert}</div>
              )}
              <ProfileIcon
                image={addSpeaker.speakerIcon}
                setImage={(img) =>
                  setAddSpeaker({ ...addSpeaker, speakerIcon: img })
                }
                title="Speaker Picture"
              />
              <div className="form-group">
                <div className="form-group-label">Name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    onChange={handleEditChange}
                    value={addSpeaker.name}
                  />
                  <span className="error">{err.name}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">First Name</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter first name"
                    name="firstName"
                    onChange={handleEditChange}
                    value={addSpeaker.firstName}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Last Name</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter name"
                    name="lastName"
                    onChange={handleEditChange}
                    value={addSpeaker.lastName}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Display Name</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter display name"
                    name="displayName"
                    onChange={handleEditChange}
                    value={addSpeaker.displayName}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Email*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter email"
                    name="email"
                    onChange={handleEditChange}
                    value={addSpeaker.email}
                  />
                  <span className="error">{err.email}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Company</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter company"
                    name="company"
                    onChange={handleEditChange}
                    value={addSpeaker.company}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Profession</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter profession"
                    name="profession"
                    onChange={handleEditChange}
                    value={addSpeaker.profession}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Phone no.</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter phone no."
                    name="phone"
                    onChange={handleEditChange}
                    value={addSpeaker.phone}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Facebook</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter facebook link"
                    name="facebook"
                    onChange={handleEditChange}
                    value={addSpeaker.facebook}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Linkedin</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter linkedin link"
                    name="linkedin"
                    onChange={handleEditChange}
                    value={addSpeaker.linkedin}
                  />
                </div>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {addInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleAddSpeakerSubmit}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <AlertComponent />
      <div
        className="modal fade edit-user-details-popup"
        id="addtag"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create tag</h2>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#addtag").modal("hide");
                    handleCloseAddModel(e);
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showalertAdd && (
                <div className="alert alert-info">{alertmsgAdd}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Tag name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="enter tags name"
                    name="tag"
                    onChange={handleAddChange}
                    value={addtag}
                  />
                </div>
                <span className="error">{adderr}</span>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddVideo;
